import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__Works extends VScroll_Item {
    _call;
    _sliders = [];

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        [... GetBy.class("__slider")].map(dom => {
            this._sliders.push( new SliderScroll(dom, {
                interaction: true,
                hasScrollbar: false,
                itemClass: ScrollItem__SliderImages__Item
            }))
        });

        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._sliders) {
            this._sliders.map(slider => slider.resize());
        }
    }

    loop() {
        if (this._sliders) {
            this._sliders.map(slider => slider.loop());
        }
    }

    dispose() {
        if (this._sliders) {
            this._sliders.map(slider => slider.dispose());
        }
        super.dispose();
    }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
    hasHiddenEnabled = false;
    info;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    //update () {
    /* if (this.progress > .5) {
       const p = Math.max(0, Maths.normalize(1, .5, this.progress));
       this._x = this._x + ((Metrics.WIDTH * .5) * p);
     }*/
    /* super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }*/
}

Scroll._registerClass('Works', ScrollItem__Works);
