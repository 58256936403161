import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Ease } from '../_app/cuchillo/utils/Ease';

class ScrollItem__BlockHero extends VScroll_Item {
  lines;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.lines = GetBy.selector(".__line svg", __link);
    
    for(let i=0, j=this.lines.length; i<j; i++) {
      gsap.set(this.lines, {y:"100%", force3D:true});
    }
    
    let delay = 0;
    this.onShow = () => {
      for(let i=0, j=this.lines.length; i<j; i++) {
        gsap.to(this.lines[i], {y:0, duration: .8+(i*.2), ease:Ease.EASE_CUCHILLO_OUT, force3D:true, delay:delay + i *.2});
      } 
    };
  }
}

Scroll._registerClass('Hero', ScrollItem__BlockHero);